@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100..900&display=swap');

* {
    font-family: 'Vazirmatn', sans-serif!important;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace!important;
}

[dir=rtl] .search-box {
    direction: rtl;
}

.search-box input {
    text-align: center;
}

.header {
    padding: .5rem 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    backdrop-filter: blur(7px);
    z-index: 100;
}

.layout-children {
    padding-top: 3rem;
}

.layout-CenterFocus-children__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 3rem);
}

@media screen and (max-width: 768px) {
    body {
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 400px) {
    .mantine-Container-root {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}